import {type Permission} from '@hconnect/apiclient'
import {NavItem} from '@hconnect/uikit/src/lib2'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useParams} from 'react-router-dom'

import {checkFeatureFlag} from '../../shared/helpers/featureFlagHelpers'
import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {
  hasChecklistsPermission,
  hasViewReliabilityEngineerDashboardPermission,
  hasViewProcessEngineerDashboardPermission,
  hasViewOptimizationSpecialistDashboardPermission
} from '../utils/permissions'

import {
  CHECKLISTS,
  OPTIMIZATION_SPECIALIST_DASHBOARD,
  PLANT_CHECKLISTS_TEMPLATES,
  PLANT_RELIABILITY_ENGINEER_DASHBOARD,
  RELIABILITY_ENGINEER_DASHBOARD,
  PROCESS_ENGINEER_DASHBOARD,
  PLANT_PROCESS_ENGINEER_DASHBOARD,
  PLANT_OPTIMIZATION_SPECIALIST_DASHBOARD
} from './routes'

type PathParameter = {
  plantId?: string
}

export const useGetHrocLinks = ({permissions}: {permissions: Permission[]}): NavItem[] => {
  const {plantId} = useParams<PathParameter>()
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  const areChecklistsEnabled = checkFeatureFlag('checklists')
  const isOptimizationSpecialistDashboardEnabled = checkFeatureFlag(
    'optimizationSpecialistDashboard'
  )
  const userHasChecklistsPermission = useMemo(
    () => hasChecklistsPermission(permissions),
    [permissions]
  )
  const userHasReliabilityEngineerPermission = useMemo(
    () => hasViewReliabilityEngineerDashboardPermission(permissions),
    [permissions]
  )

  const userHasProcessEngineerPermission = useMemo(
    () => hasViewProcessEngineerDashboardPermission(permissions),
    [permissions]
  )

  const userHasOptimizationSpecialistPermission = useMemo(
    () => hasViewOptimizationSpecialistDashboardPermission(permissions),
    [permissions]
  )

  const checklistNavItem: NavItem = {
    url: plantId ? generatePath(PLANT_CHECKLISTS_TEMPLATES, {plantId}) : CHECKLISTS,
    label: t(`${hrocPrefix}.navPages.checklists`)
  }

  const reliabilityEngineerDashboardNavItem: NavItem = {
    url: plantId
      ? generatePath(PLANT_RELIABILITY_ENGINEER_DASHBOARD, {plantId})
      : RELIABILITY_ENGINEER_DASHBOARD,
    label: t(`${hrocPrefix}.navPages.reliabilityEngineerDashboard`)
  }

  const processEngineerDashboardNavItem: NavItem = {
    url: plantId
      ? generatePath(PLANT_PROCESS_ENGINEER_DASHBOARD, {plantId})
      : PROCESS_ENGINEER_DASHBOARD,
    label: t(`${hrocPrefix}.navPages.processEngineerDashboard`),
    dataTestId: 'process-engineer-dashboard-nav-item'
  }

  const optimizationSpecialistDashboardNavItem: NavItem = {
    url: plantId
      ? generatePath(PLANT_OPTIMIZATION_SPECIALIST_DASHBOARD, {plantId})
      : OPTIMIZATION_SPECIALIST_DASHBOARD,
    label: t(`${hrocPrefix}.navPages.optimizationSpecialistDashboard`),
    dataTestId: 'optimization-specialist-dashboard-nav-item'
  }

  const navItems: NavItem[] = []

  if (areChecklistsEnabled && userHasChecklistsPermission) {
    navItems.push(checklistNavItem)
  }
  if (userHasReliabilityEngineerPermission) {
    navItems.push(reliabilityEngineerDashboardNavItem)
  }
  if (userHasProcessEngineerPermission) {
    navItems.push(processEngineerDashboardNavItem)
  }
  if (isOptimizationSpecialistDashboardEnabled && userHasOptimizationSpecialistPermission) {
    navItems.push(optimizationSpecialistDashboardNavItem)
  }

  return navItems
}
