import {
  DataContentWrapper as SharedDataContentWrapper,
  type DataContentWrapperProps
} from '../../shared/components'
import {useTranslationPrefix} from '../../shared/hooks'

export function DataContentWrapper<T>(props: DataContentWrapperProps<T>) {
  const {hrocPrefix} = useTranslationPrefix()

  return <SharedDataContentWrapper {...props} translationPrefix={hrocPrefix} />
}
