import {LegendItem} from '@hconnect/common/components/runningTimes/LegendItem'
import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import {Box, Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../../shared/hooks'
import {mapToWorkOrderSummaryChartDatasets} from '../../mappers'
import {AggregatedWorkOrderSummaryDto, WorkOrderSummaryChartDataset} from '../../types'

import {BarForWorkOrderChart} from './BarForWorkOrderChart'

function getBarPercentage(value: number, totalWorkOrders: number) {
  if (!value || value === 0) {
    return '0%'
  }

  return ((value / totalWorkOrders) * 100).toString() + '%'
}

const getWorkOrderLabel = (workOrderTypeLabel: string, percentageValue: string) => {
  const roundedPercentageValue = Math.round(parseFloat(percentageValue)).toString()

  return `${workOrderTypeLabel} (${roundedPercentageValue}%)`
}

interface Props {
  workOrdersSummary: AggregatedWorkOrderSummaryDto
}

const EMPTY_DATASETS_COLOR = customThemeConstants().palette.neutralFaded20

const createEmptyDatasetLegend = (datasets: WorkOrderSummaryChartDataset[]) => {
  return datasets.map((summary) => ({
    ...summary,
    color: EMPTY_DATASETS_COLOR
  }))
}

export const WorkOrdersStackedBarChart: React.FC<Props> = ({workOrdersSummary}) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const totalCount = workOrdersSummary.totalWorkOrders
  const mappedWorkOrders = mapToWorkOrderSummaryChartDatasets(workOrdersSummary)
  const workOrdersWithValues = mappedWorkOrders.filter((summary) => summary.count > 0)
  const isEmptyDataset = workOrdersWithValues.length === 0
  const emptyDatasetLegend = createEmptyDatasetLegend(mappedWorkOrders)

  return (
    <>
      <Box
        sx={{
          flex: '1',
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'flex-start',
          borderRadius: 1,
          overflowY: 'hidden'
        }}
      >
        {workOrdersWithValues.map((workOrder) => {
          const barPercentage = getBarPercentage(workOrder.count, totalCount)

          return (
            <BarForWorkOrderChart
              key={`${workOrder.typeName}`}
              workOrder={workOrder}
              percentage={barPercentage}
              tooltip={getWorkOrderLabel(
                t(`${hrocPrefix}.workOrderSummary.sapWorkOrderTypes.${workOrder.typeName}`),
                barPercentage
              )}
            />
          )
        })}
      </Box>
      <Box sx={{minWidth: 230}}>
        <WorkOrdersSummaryChartLegend
          data={isEmptyDataset ? emptyDatasetLegend : mappedWorkOrders}
        />
      </Box>
    </>
  )
}

type WorkOrdersSummaryChartLegendProps = {
  data: WorkOrderSummaryChartDataset[]
}

const WorkOrdersSummaryChartLegend = ({data}: WorkOrdersSummaryChartLegendProps) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  return (
    <Stack spacing={1} data-test-id="work-order-summary-chart-legend">
      {data.map(({typeName, color, count}) => {
        const nameLabel = t(`${hrocPrefix}.workOrderSummary.sapWorkOrderTypes.${typeName}`)

        return (
          <LegendItem
            key={typeName}
            color={color}
            label={`${nameLabel} (${count})`}
            data-test-id={`legend-item-for-${typeName}`}
          />
        )
      })}
    </Stack>
  )
}
