import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useNavigate} from 'react-router-dom'

import {ApiPath} from '../../../shared/consts'
import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {ChecklistTemplateDto} from '../../types'

const QueryKey = 'checklist-templates'
const STALE_TIME_IN_MS = 1000 * 60 * 15

export const useChecklistTemplates = <TSelect = ChecklistTemplateDto[]>(
  {plantId, ...searchParams}: {plantId: string; templateTypes?: string[]},
  options?: UseQueryOptions<ChecklistTemplateDto[], AxiosError, TSelect>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<ChecklistTemplateDto[], AxiosError, TSelect>(
    [QueryKey, plantId, searchParams],
    async () => {
      const response = await axiosInstance.get<ChecklistTemplateDto[]>(
        `${ApiPath.HROC_CHECKLISTS}/${plantId}/checklist-templates`,
        {
          params: searchParams
        }
      )

      return response.data
    },
    {
      staleTime: STALE_TIME_IN_MS,
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
