import {getTranslationKey} from '@hconnect/common/utils'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {Moment} from 'moment'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'

import {ApiPath} from '../../../../shared/consts'
import {useAxios, useTranslationPrefix} from '../../../../shared/hooks'
import {MonthlyKpiSummaryDto} from '../../../types'

const QueryKey = 'equipment-performances-monthly'

type MonthlyAggregatedKpisParams = {
  plantId: string
  from: Moment
  to: Moment
}

export const useEquipmentPerformancesMonthly = <TSelect = MonthlyKpiSummaryDto[]>(
  {plantId, ...searchParams}: MonthlyAggregatedKpisParams,
  options?: UseQueryOptions<MonthlyKpiSummaryDto[], AxiosError, TSelect>
) => {
  const axiosInstance = useAxios()
  const {enqueueSnackbar} = useSnackbar()
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const {from, to} = searchParams
  const params = {
    fromYear: from.year(),
    fromMonth: from.month() + 1, // Adjusting because Moment.js months are 0-indexed
    toYear: to.year(),
    toMonth: to.month() + 1
  }

  return useQuery<MonthlyKpiSummaryDto[], AxiosError, TSelect>({
    queryKey: [QueryKey, plantId, from.toISOString(), to.toISOString()],
    queryFn: async () => {
      const path = `${ApiPath.KPI_RESULT}/${plantId}/equipment-performances/monthly`
      const response = await axiosInstance.get<MonthlyKpiSummaryDto[]>(path, {params})

      return response.data
    },
    onError: () => {
      enqueueSnackbar(
        t(getTranslationKey('plantStatus.message.failedToFetchMonthlyKPIs', hrocPrefix)),
        {
          variant: 'error'
        }
      )
    },
    ...options
  })
}
