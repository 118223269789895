import {getMaintainPlantKmsUrl} from '@hconnect/common/utils'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {RadioButtonChecked} from '@mui/icons-material'
import {Box, Chip, SxProps, Theme, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks'
import {CardLinkWrapper, DataContentWrapper, KilnMonitoring} from '../components'
import {useKilnMonitoringState} from '../hooks'

type Props = {
  plantId: string
  cardBoxSx?: SxProps<Theme>
  placeholderWrapperSx?: SxProps<Theme>
}

export const KilnMonitoringContainer: React.FC<Props> = ({
  plantId,
  cardBoxSx,
  placeholderWrapperSx
}) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const {kilnsInfo, selectedKiln, healthIndicators, sensorAreas, handleSelectedKilnChange} =
    useKilnMonitoringState(plantId)

  const linkPath = getMaintainPlantKmsUrl(plantId, selectedKiln?.id)

  return (
    <CardLinkWrapper href={linkPath} data-test-id="kiln-monitoring-link-to-maintain">
      <CardBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          ...cardBoxSx,
          px: 0,
          pl: 3
        }}
        data-test-id="kiln-monitoring-card"
      >
        <Box display="flex" flexDirection="row">
          <Box flex={1}>
            <Typography variant="h3" mb={2}>
              {t(`${hrocPrefix}.kilnMonitoring.cardTitle`)}
            </Typography>
          </Box>
          <Chip
            icon={<RadioButtonChecked />}
            label={t(`${hrocPrefix}.label.live`)}
            size="small"
            sx={{mx: 1, mr: 4}}
          />
        </Box>
        <DataContentWrapper
          showLoader={kilnsInfo.isInitialLoading}
          isError={kilnsInfo.isError}
          isRefetching={kilnsInfo.isRefetching}
          data={selectedKiln}
          retryFunction={() => void kilnsInfo.refetch()}
          progressSx={{color: 'primary.main'}}
          placeholderWrapperSx={placeholderWrapperSx}
        >
          <Box sx={{flex: 1, overflowY: 'auto', pr: 3}}>
            <KilnMonitoring
              isLoading={kilnsInfo.isInitialLoading}
              kilns={kilnsInfo.data}
              selectedKiln={selectedKiln}
              isLoadingHealthIndicators={healthIndicators.isInitialLoading}
              healthIndicators={healthIndicators.data}
              onSelectedKilnChange={handleSelectedKilnChange}
              isLoadingSensors={sensorAreas.isInitialLoading}
              sensorAreas={sensorAreas.data}
            />
          </Box>
        </DataContentWrapper>
      </CardBox>
    </CardLinkWrapper>
  )
}
