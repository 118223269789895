import {FlexPage} from '@hconnect/common/components'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Stack, useMediaQuery, useTheme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'

import {useTranslationPrefix} from '../../shared/hooks'
import {BOX_HEIGHTS, BOX_WIDTHS} from '../consts'

type PathParameter = {
  plantId?: string
}

export const OptimizationSpecialistDashboard = () => {
  const {plantId} = useParams<PathParameter>()
  if (!plantId) throw new Error('plantId is missing')

  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  return (
    <FlexPage title={t(`${hrocPrefix}.optimizationSpecialistDashboard.pageTitle`)}>
      <Layout
        plantStatus={<CardBox sx={{height: BOX_HEIGHTS['XL']}}>Plant status placeholder</CardBox>}
        comments={
          <CardBox sx={{height: BOX_HEIGHTS['XL'], width: {lg: BOX_WIDTHS['L']}}}>
            Comments placeholder
          </CardBox>
        }
        quality={<CardBox sx={{height: BOX_HEIGHTS['M']}}>Quality placeholder</CardBox>}
        operationHours={
          <CardBox sx={{height: BOX_HEIGHTS['M'], width: '100%'}}>
            Operation hours placeholder
          </CardBox>
        }
        events={
          <CardBox sx={{height: BOX_HEIGHTS['S'], width: {sm: BOX_WIDTHS['S']}}}>
            Events placeholder
          </CardBox>
        }
        shiftTasks={
          <CardBox sx={{height: BOX_HEIGHTS['S'], width: {sm: BOX_WIDTHS['S']}}}>
            Shift tasks placeholder
          </CardBox>
        }
      />
    </FlexPage>
  )
}

type LayoutProps = {
  plantStatus: React.ReactNode
  comments: React.ReactNode
  quality: React.ReactNode
  operationHours: React.ReactNode
  events: React.ReactNode
  shiftTasks: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({
  plantStatus,
  comments,
  quality,
  operationHours,
  events,
  shiftTasks
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'))
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const spacing = isMobile ? 1 : 2
  return isLarge ? (
    <Stack direction="row" spacing={spacing} flexGrow={1} useFlexGap flexWrap="wrap">
      <Stack spacing={spacing} flex={1} useFlexGap>
        {plantStatus}
        {quality}
      </Stack>
      <Stack spacing={spacing} useFlexGap>
        {comments}
        <Stack direction="row" spacing={spacing}>
          {operationHours}
          <Stack spacing={spacing}>
            {events}
            {shiftTasks}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  ) : (
    <Stack spacing={spacing} flexGrow={1} useFlexGap flexWrap="wrap">
      <Stack spacing={spacing} flex={1} useFlexGap>
        {plantStatus}
        {comments}
        {quality}
      </Stack>
      <Stack spacing={spacing} flex={1} direction={isTablet ? 'row' : 'column'} useFlexGap>
        {operationHours}
        <Stack spacing={isTablet ? 2 : 1}>
          {events}
          {shiftTasks}
        </Stack>
      </Stack>
    </Stack>
  )
}
