import {OperatingHoursLegendList} from '@hconnect/common/components/runningTimes'
import {mapRunningTimeDto} from '@hconnect/common/mappers'
import {EquipmentData, Iso8601, RunningTime, EquipmentRunningTimesDto} from '@hconnect/common/types'
import {Box, Grid} from '@mui/material'
import {get, last} from 'lodash'
import moment from 'moment'
import React, {useCallback} from 'react'

import {StackedBarChart} from '../../shared/components/charts'
import {useConfigData} from '../../shared/hooks/useConfig'
import {mapEquipmentRunningTimes} from '../../shared/mappers'

import {EquipmentRunningTimesBarChart} from './EquipmentRunningTimesBarChart'

type Props = {
  runningTimePerformances?: EquipmentRunningTimesDto[] | undefined
  translationPrefix: string
  from: Iso8601
  to: Iso8601
  timezone: string
  onEquipmentClick: (equipmentId: string) => void
  oneColumnView?: boolean
}

export const OperationHoursList: React.FC<Props> = React.memo(
  ({
    runningTimePerformances,
    translationPrefix,
    from,
    to,
    timezone,
    onEquipmentClick,
    oneColumnView
  }) => {
    const config = useConfigData()

    const getStackedBarChartComponent = useCallback(
      (equipment: EquipmentData, runningTimes: RunningTime[]) => {
        return (
          <StackedBarChart
            timeRange={{
              startDate: moment(from),
              endDate: moment(to)
            }}
            timezone={timezone}
            data={runningTimes.map(mapRunningTimeDto)}
            equipment={equipment}
            config={config}
          />
        )
      },
      [config, from, to, timezone]
    )

    return (
      <>
        <OperatingHoursLegendList translationPrefix={translationPrefix} />
        {oneColumnView ? (
          runningTimePerformances?.map((equipmentPerformanceData) => {
            const activeStatus = get(last(equipmentPerformanceData.runningTimes), 'runningTimeType')

            return (
              <Box sx={{width: '100%'}} key={equipmentPerformanceData.equipment.id}>
                <EquipmentRunningTimesBarChart
                  translationPrefix={translationPrefix}
                  runningTimePerformance={mapEquipmentRunningTimes([equipmentPerformanceData])[0]}
                  equipment={equipmentPerformanceData.equipment}
                  getStackedBarChartComponent={getStackedBarChartComponent}
                  activeStatus={activeStatus}
                  onClick={() => onEquipmentClick(equipmentPerformanceData.equipment.id)}
                  sx={{mb: 1}}
                />
              </Box>
            )
          })
        ) : (
          <Grid rowSpacing={1} columnSpacing={3} container overflow={'hidden'}>
            {runningTimePerformances?.map((equipmentPerformanceData) => {
              const activeStatus = get(
                last(equipmentPerformanceData.runningTimes),
                'runningTimeType'
              )

              return (
                <Grid item xs={6} key={equipmentPerformanceData.equipment.id}>
                  <EquipmentRunningTimesBarChart
                    translationPrefix={translationPrefix}
                    runningTimePerformance={mapEquipmentRunningTimes([equipmentPerformanceData])[0]}
                    equipment={equipmentPerformanceData.equipment}
                    getStackedBarChartComponent={getStackedBarChartComponent}
                    activeStatus={activeStatus}
                    onClick={() => onEquipmentClick(equipmentPerformanceData.equipment.id)}
                  />
                </Grid>
              )
            })}
          </Grid>
        )}
      </>
    )
  }
)
