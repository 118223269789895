import {ChecklistForm, DataContentWrapper} from '../components'
import {useChecklist, useChecklistUpdate} from '../hooks/api'
import {ChecklistStatus} from '../types'

type Props = {
  checklistId: string
  plantId: string
  backToTargetLabel: string
  onCancel: () => void
}

export const ChecklistContainer = ({checklistId, plantId, backToTargetLabel, onCancel}: Props) => {
  const {
    data: checklist,
    isInitialLoading,
    isError,
    isRefetching,
    refetch
  } = useChecklist({id: checklistId, plantId})
  const updateMutation = useChecklistUpdate({id: checklistId, plantId})
  const isReadOnly = checklist?.status === ChecklistStatus.DONE

  return (
    <DataContentWrapper
      showLoader={isInitialLoading}
      isError={isError}
      isRefetching={isRefetching}
      data={checklist}
      retryFunction={() => void refetch()}
    >
      <ChecklistForm
        checklist={checklist!}
        readOnly={isReadOnly}
        updateMutation={updateMutation}
        onCancel={onCancel}
        backToTargetLabel={backToTargetLabel}
      />
    </DataContentWrapper>
  )
}
