import {Box, Tooltip, Typography} from '@mui/material'
import React, {ReactNode} from 'react'

import {WorkOrderSummaryChartDataset} from '../../types'

type BarForWorkOrderChartProps = {
  percentage: string
  workOrder: WorkOrderSummaryChartDataset
  tooltip?: ReactNode
}

export const BarForWorkOrderChart: React.FC<BarForWorkOrderChartProps> = ({
  percentage,
  workOrder,
  tooltip
}) => {
  return (
    <Tooltip
      arrow
      title={<Typography data-test-id={`tooltip-${workOrder.typeName}`}>{tooltip}</Typography>}
    >
      <Box
        sx={{
          flexBasis: percentage,
          zIndex: 1,
          backgroundColor: workOrder.color,
          height: 12
        }}
        data-test-id={`bar-for-workOrder-chart-${workOrder.typeName}`}
      />
    </Tooltip>
  )
}
