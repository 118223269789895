import type {KilnInfo} from '@hconnect/common/components/kmsStatus'
import type {HttpError} from '@hconnect/common/types'
import {useQuery, type UseQueryOptions} from '@tanstack/react-query'
import type {AxiosError} from 'axios'

import {ApiPath} from '../../../../shared/consts'
import {useAxios} from '../../../../shared/hooks/useApi'

const QueryKey = 'kilns-info'
const STALE_TIME_IN_MS = 1000 * 60 * 60

export const useGetKilnsInfo = (
  plantId: string,
  options?: UseQueryOptions<KilnInfo[], AxiosError<HttpError>>
) => {
  const axiosInstance = useAxios()

  return useQuery<KilnInfo[], AxiosError<HttpError>>({
    queryKey: [QueryKey, plantId],
    queryFn: async () => {
      const path = `${ApiPath.KMS}/${plantId}/kilns`
      const response = await axiosInstance.get<KilnInfo[]>(path)

      return response.data
    },
    staleTime: STALE_TIME_IN_MS,
    refetchOnWindowFocus: false,
    ...options
  })
}
