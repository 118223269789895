import {
  EquipmentRunningTimes,
  RunningTime,
  RunningTimesEquipmentData,
  RunningTimeType
} from '@hconnect/common/types'
import {getHoursMinutes, getTranslationKey} from '@hconnect/common/utils'
import {isKilnRunning} from '@hconnect/common/utils/operationHoursHelpers'
import {FiberManualRecord as Circle} from '@mui/icons-material'
import SquareIcon from '@mui/icons-material/Square'
import {Box, SxProps, Theme, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

const statusStyle = {display: 'flex', alignItems: 'center', gap: 0.5, color: 'text.primary'}

const getStatusIndicator = (activeStatus?: RunningTimeType, equipmentText?: string) => (
  <>
    {activeStatus && isKilnRunning(activeStatus) && (
      <Typography mr={2} sx={statusStyle} variant="caption">
        <Circle
          sx={(theme: Theme) => ({
            color: theme.palette.success.light,
            verticalAlign: 'text-bottom',
            height: 18,
            width: 18
          })}
        />
        {equipmentText}
      </Typography>
    )}
    {activeStatus && !isKilnRunning(activeStatus) && (
      <Typography mr={2} sx={statusStyle} variant="caption">
        <SquareIcon
          sx={(theme: Theme) => ({
            color: theme.palette.error.light,
            verticalAlign: 'text-bottom',
            height: 16,
            width: 16
          })}
        />
        {equipmentText}
      </Typography>
    )}
  </>
)

type Props = {
  equipment: RunningTimesEquipmentData
  runningTimePerformance?: EquipmentRunningTimes
  translationPrefix?: string
  getStackedBarChartComponent?: (
    equipment: RunningTimesEquipmentData,
    runningTimes: RunningTime[]
  ) => React.ReactNode
  activeStatus?: RunningTimeType
  onClick?: () => void
  sx?: SxProps<Theme>
}

export const EquipmentRunningTimesBarChart: React.FC<Props> = ({
  equipment,
  getStackedBarChartComponent,
  runningTimePerformance,
  translationPrefix,
  activeStatus,
  onClick,
  sx
}) => {
  const {t} = useTranslation()

  return (
    <Box
      data-test-id={`equipment-rt-${equipment.id}`}
      sx={[!!onClick && {cursor: 'pointer'}]}
      onClick={onClick}
      width={'100%'}
    >
      <Box sx={{...sx, width: '100%'}}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle1" display="flex" gap={2}>
            {getStatusIndicator(activeStatus, equipment.text)}
          </Typography>
          {runningTimePerformance?.totalStopped && (
            <Typography display="flex" fontWeight="bold" fontSize={12} sx={{...sx}}>
              {/* show only hours and minutes */}
              {`${t(getTranslationKey('label.stopped', translationPrefix))} ${getHoursMinutes(
                runningTimePerformance.totalStopped
              )} h`}
            </Typography>
          )}
        </Box>
        <Box sx={{display: 'flex', alignItems: 'stretch', flexWrap: 'wrap', width: '100%'}}>
          <Box
            sx={{
              flex: '1',
              display: 'flex',
              alignItems: 'stretch',
              justifyContent: 'flex-end',
              borderRadius: 1,
              overflow: 'hidden'
            }}
          >
            {runningTimePerformance &&
              getStackedBarChartComponent?.(
                equipment,
                runningTimePerformance.runningTimes?.filter((rt) => rt.begin !== rt.end) || []
              )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
