import type {SignalData} from '@hconnect/common/components/kmsStatus'
import type {HttpError} from '@hconnect/common/types'
import {useQuery, type UseQueryOptions} from '@tanstack/react-query'
import type {AxiosError} from 'axios'

import {ApiPath} from '../../../../shared/consts'
import {useAxios} from '../../../../shared/hooks/useApi'

const QueryKey = 'sensors'
const REFETCH_INTERVAL_IN_MS = 1000 * 30

type SignalsParams = {
  plantId: string
  kilnId: string
}

export const useGetSignals = <TData = SignalData[]>(
  {plantId, kilnId}: SignalsParams,
  options?: UseQueryOptions<SignalData[], AxiosError<HttpError>, TData>
) => {
  const axiosInstance = useAxios()

  return useQuery<SignalData[], AxiosError<HttpError>, TData>({
    queryKey: [QueryKey, plantId, kilnId],
    queryFn: async () => {
      const path = `${ApiPath.KMS}/${plantId}/signals`
      const response = await axiosInstance.get<SignalData[]>(path, {
        params: {
          departmentId: kilnId
        }
      })

      return response.data
    },
    refetchInterval: REFETCH_INTERVAL_IN_MS,
    ...options
  })
}
