import {KpiScore} from '@hconnect/uikit/src/lib2'
import {InfoOutlined} from '@mui/icons-material'
import {Box, Stack, Tooltip} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import type {AggregatedWorkOrderSummaryDto} from '../types'

import {WorkOrdersStackedBarChart} from './charts'
import {WorkOrdersOverdueTooltipTitle} from './WorkOrdersOverdueTooltipTitle'

type Props = {
  data?: AggregatedWorkOrderSummaryDto
}

export const WorkOrdersSummary = ({data}: Props) => {
  if (!data) return null

  return (
    <Stack spacing={2} useFlexGap sx={{overflowY: 'auto'}}>
      <DataSummary
        total={data.totalWorkOrders}
        overdue={data.overdueWorkOrders}
        totalOverdue={data.totalOverdueWorkOrders}
        closed={data.closedWorkOrders}
      />
      <WorkOrdersStackedBarChart workOrdersSummary={data} />
    </Stack>
  )
}

type DataSummaryProps = {
  total: number
  overdue: number
  totalOverdue: number
  closed: number
}

const DataSummary = ({total, overdue, totalOverdue, closed}: DataSummaryProps) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const isOverdueWarningShown = totalOverdue > 0 && overdue !== totalOverdue
  const overdueVariant = totalOverdue > 0 ? 'negative' : 'neutral'

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        alignItems: 'flex-start',
        justifyContent: 'space-between'
      }}
    >
      <StackItem>
        <KpiScore
          label={t(`${hrocPrefix}.workOrderSummary.label.total`)}
          value={total}
          data-test-id="total-work-orders"
        />
      </StackItem>
      <StackItem>
        <KpiScore
          label={t(`${hrocPrefix}.workOrderSummary.label.closed`)}
          value={closed}
          data-test-id="closed-work-orders"
        />
      </StackItem>
      <StackItem>
        <Tooltip
          title={
            isOverdueWarningShown ? (
              <WorkOrdersOverdueTooltipTitle overdue={overdue} totalOverdue={totalOverdue} />
            ) : (
              ''
            )
          }
          arrow
          placement="top"
        >
          <span>
            <KpiScore
              label={t(`${hrocPrefix}.workOrderSummary.label.overdue`)}
              labelIcon={isOverdueWarningShown ? <InfoOutlined /> : undefined}
              value={totalOverdue}
              variant={overdueVariant}
              data-test-id="overdue-work-orders"
            />
          </span>
        </Tooltip>
      </StackItem>
    </Stack>
  )
}

const StackItem: React.FC<{children: React.ReactNode}> = ({children}) => (
  <Box
    sx={{
      display: 'flex'
    }}
  >
    {children}
  </Box>
)
