import {useQuery, type UseQueryOptions} from '@tanstack/react-query'
import {type AxiosError} from 'axios'
import {useNavigate} from 'react-router-dom'

import {ApiPath} from '../../../shared/consts'
import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {type ChecklistDoneForTemplateDto, SortOrder, type PagedResponse} from '../../types'

const QueryKey = 'checklist-done-for-template'

export const useChecklistDoneForTemplate = (
  {
    plantId,
    templateId,
    page = 0,
    rowsPerPage = 10,
    sortOrder = SortOrder.DESCENDING
  }: {
    plantId: string
    templateId: string
    page?: number
    rowsPerPage?: number
    sortOrder?: SortOrder
  },
  options?: UseQueryOptions<PagedResponse<ChecklistDoneForTemplateDto>, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  const offset = page * rowsPerPage
  const limit = rowsPerPage

  return useQuery<PagedResponse<ChecklistDoneForTemplateDto>, AxiosError>(
    [QueryKey, plantId, offset, limit, sortOrder],
    async () => {
      const response = await axiosInstance.get<PagedResponse<ChecklistDoneForTemplateDto>>(
        `${ApiPath.HROC_CHECKLISTS}/${plantId}/checklists/done-for-template/${templateId}`,
        {
          params: {offset, limit, sortOrder}
        }
      )

      return response.data
    },
    {
      onError: (err) => handleError(err, navigate),
      keepPreviousData: true,
      ...options
    }
  )
}
