import {Box, Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'

import {MyTasksSummaryScore} from './MyTasksSummaryScore'
export const MyTasksSummary = ({open, overdue, done}) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  const showOverdueTooltip = overdue > 0
  const showOpenTooltip = open > 0
  const overdueVariant = showOverdueTooltip ? 'negative' : 'neutral'
  return (
    <Stack direction="row" spacing={3} useFlexGap>
      <StackItem>
        <MyTasksSummaryScore
          label={t(`${hrocPrefix}.myTasksSummary.label.open`)}
          value={open}
          valueVariant="neutral"
          showTooltip={showOpenTooltip}
          tooltipText={t(`${hrocPrefix}.myTasksSummary.tooltip.open`)}
          dataTestId="my-tasks-open"
        />
      </StackItem>
      <StackItem>
        <MyTasksSummaryScore
          label={t(`${hrocPrefix}.myTasksSummary.label.done`)}
          value={done}
          valueVariant="neutral"
          dataTestId="my-tasks-done"
        />
      </StackItem>
      <StackItem>
        <MyTasksSummaryScore
          label={t(`${hrocPrefix}.myTasksSummary.label.overdue`)}
          value={overdue}
          valueVariant={overdueVariant}
          showTooltip={showOverdueTooltip}
          tooltipText={t(`${hrocPrefix}.myTasksSummary.tooltip.overdue`)}
          dataTestId="my-tasks-overdue"
        />
      </StackItem>
    </Stack>
  )
}

const StackItem: React.FC<{children: React.ReactNode}> = ({children}) => (
  <Box
    sx={{
      display: 'flex',
      flex: '1 0 0'
    }}
  >
    {children}
  </Box>
)
